export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Space Waves Game",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://games.crazygames.com/en_US/space-waves/index.html?v=1.291",
    domain: "spacewavesgame.online",
    gaId: "G-RLG170H4JP",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
